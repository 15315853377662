








































































import { computed, defineComponent, Ref, ref } from '@vue/composition-api';
import { useForm, Form, EntryProperty } from '@/admin/form';
import { useNotification } from '@/composition/notification';
import FcDate from '@/components/FcDate.vue';
import FcCollection from '@/components/FcCollection.vue';
import { uiTypes } from '@/admin/uiType';
import { getSettingUserProperties } from '@/admin/setting';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

interface RequiredProperties extends EntryProperty {
  isActive: boolean;
  note: string;
  disabled?: boolean;
}

export default defineComponent({
  name: 'Form',
  components: {
    FcDate,
    FcCollection,
    FcRoleLoading,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('properties'));

    const notification = useNotification();
    const { getForm, saveForm } = useForm();
    const form: Ref<Form | null> = ref(null);
    const optionalProperties: Ref<EntryProperty[]> = ref([]);
    const requiredProperties: Ref<RequiredProperties[]> = ref([
      {
        key: 'name',
        isActive: false,
        required: false,
        title: '名前',
        uiType: uiTypes.TEXT,
        uiOptionsString: '',
        note: '',
        displayNote: '',
      },
      {
        key: 'nickname',
        isActive: false,
        required: false,
        title: 'ニックネーム',
        uiType: uiTypes.TEXT,
        uiOptionsString: '',
        note: '',
        displayNote: 'ニックネームは必須項目です',
        disabled: true,
      },
      {
        key: 'birthdate',
        isActive: false,
        required: false,
        title: '生年月日',
        uiType: uiTypes.TEXT,
        uiOptionsString: '',
        note: '',
        displayNote: '',
      },
      {
        key: 'gender',
        isActive: false,
        required: false,
        title: '性別',
        uiType: uiTypes.TEXT,
        uiOptionsString: '',
        note: '',
        displayNote: '',
      },
      {
        key: 'postalCode',
        isActive: false,
        required: false,
        title: '郵便番号',
        uiType: uiTypes.TEXT,
        uiOptionsString: 'address-postal',
        note: '',
        displayNote: '住所登録が必要な場合ONにする',
      },
      {
        key: 'prefecture',
        isActive: false,
        required: false,
        title: '都道府県 or 居住地',
        uiType: uiTypes.TEXT,
        uiOptionsString: 'address-prefecture',
        note: '',
        displayNote: '',
      },
      {
        key: 'address01',
        isActive: false,
        required: false,
        title: '市区町村以降のご住所',
        uiType: uiTypes.TEXT,
        uiOptionsString: 'address-address01',
        note: '',
        displayNote: '住所登録が必要な場合ONにする',
      },
      {
        key: 'address02',
        isActive: false,
        required: false,
        title: '建物名・号室',
        uiType: uiTypes.TEXT,
        uiOptionsString: 'address-address02',
        note: '',
        displayNote: '住所登録が必要な場合ONにする。基本は任意項目。',
      },
      {
        key: 'phoneNumber',
        isActive: false,
        required: false,
        title: '電話番号',
        uiType: uiTypes.TEXT,
        uiOptionsString: '',
        note: '半角数字でご記入ください（ハイフン省略可）',
        displayNote: '',
      },
      {
        key: 'occupation',
        isActive: false,
        required: false,
        title: '職業',
        uiType: uiTypes.SELECTBOX,
        uiOptionsString: '',
        note: '',
        displayNote: '',
      },
    ]);
    const isCompleteAddress = computed(() => {
      const isAddress =
        requiredProperties.value[4].isActive &&
        requiredProperties.value[5].isActive &&
        requiredProperties.value[6].isActive &&
        requiredProperties.value[7].isActive;
      const isNotAddress =
        !requiredProperties.value[4].isActive &&
        !requiredProperties.value[6].isActive &&
        !requiredProperties.value[7].isActive;
      return isAddress || isNotAddress;
    });
    const headers = [
      { text: '', value: 'isActive', sortable: false },
      { text: 'タイトル', value: 'title', sortable: false },
      { text: '必須', value: 'required', sortable: false },
      { text: '備考', value: 'displayNote', sortable: false },
    ];

    const isAllowUpdateProperties = ref(false);
    Promise.all([getForm('_userProperties'), getSettingUserProperties()])
      .then(([_userProperties, _isAllowUpdateProperties]) => {
        if (_userProperties) {
          _userProperties.entryProperties.forEach((property) => {
            const requiredProperty = requiredProperties.value.find((item) => item.key === property.key);
            if (requiredProperty) {
              const index = requiredProperties.value.indexOf(requiredProperty);
              requiredProperties.value[index].isActive = true;
              requiredProperties.value[index].required = property.required;
            } else {
              optionalProperties.value.push(property);
            }
          });
          form.value = _userProperties;
        }
        isAllowUpdateProperties.value = _isAllowUpdateProperties.value.isAllowUpdateProperties;
      })
      .catch((error) => {
        console.error(error);
      });

    const isSaving = ref(false);
    const save = async () => {
      if (!form.value) {
        alert('no form');
        return;
      } else if (!isCompleteAddress.value) {
        alert(
          '住所の設定にあやまりがあります\r\n都道府県：郵便番号/市町区村/番地をON\r\n居住地：郵便番号/市町区村/番地をOFF'
        );
        return;
      }
      const entryProperties = requiredProperties.value
        .filter((item) => item.isActive)
        .map((property) => ({
          key: property.key,
          title: property.title,
          note: property.note,
          required: property.required,
          uiType: property.uiType,
          uiOptionsString: property.uiOptionsString,
        }));
      entryProperties.push(...optionalProperties.value);
      form.value.entryProperties = entryProperties;
      isSaving.value = true;
      try {
        await saveForm(form.value);
        notification.notify('保存しました');
      } catch (error) {
        notification.error(error);
      }
      isSaving.value = false;
    };

    return {
      pageTitle: '会員登録情報',
      myRoleSettings,
      form,
      isSaving,
      save,
      requiredProperties,
      optionalProperties,
      headers,
      isAllowUpdateProperties,
    };
  },
});
